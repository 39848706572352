
.reactionDetail {
    width: 100%;
}

.reactionDetail .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.reactionDetail .header h4 {
    padding: 0;
    margin: 0;
}

.reactionDetail .header .closeButton {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-decoration: none;
    color: var(--adito-primary-font-color);
    font-size: 24px;
}

.reactionDetail .header .closeButton:hover {
    background: #f5f5f5;
    cursor: pointer;
}

.reactionDetail .content {
    margin-top: 10px;
}

.reactionDetail .content .avatarContainer {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 8px;
    display: inline-block;
    max-height: 136px;
}

.reactionDetail .content section {
    display: flex;
    gap: 10px;
    padding-right: 10px;
}

.reactionDetail .content section .heading {
    display: flex;
    justify-content: space-between;
}

.reactionDetail .content section .sectionContent {
    margin-top: 6px;
    margin-bottom: 6px;
}

.reactionDetail .content section .heading h5 {
    margin: 0;
}

.reactionDetail .content section .actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.reactionDetail .circleButton {
    padding: 5px;
    border-radius: 50%;
    line-height: 0;
    max-height: fit-content;
    height: fit-content;
}

.reactionDetail .circleButton:hover {
    cursor: pointer;
    background: #f5f5f5;
}

.reactionDetail .content section .heading a.circleButton {
    /*margin-right: 10px;*/
}

.detailList ul li a {
    gap: 15px;
}

.reactionDetail .info {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reactionDetail .info h5 {
    margin: 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 2.66;
    letter-spacing: 0.08333em;
}

.reactionDetail .info dl {
    display: grid;
    -webkit-box-align: baseline;
    align-items: baseline;
    grid-template-columns: 12em auto;
    gap: 8px;
    font-size: 14px;
    margin-left: 24px;
}

.reactionDetail .info dt {
    color: var(--adito-secondary-font-color-variant);
    margin: 0px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.57;
    letter-spacing: 0.00714em;
}

