
.bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url('/public/images/login/bg.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: var(--adito-bg-filter);
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContent {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.loginBox {
    position: relative;
}

.loginHeader {
    display: flex;
    justify-content: center;
}

.loginContent {
    position: relative;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    margin-top: 48px;
    padding: 32px;
}

.button {
    width: 100%;
}

.loginMenu {
    position: absolute;
    top: 10px;
    right: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 1;
}

.loginMenu .item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.loginMenu .item kol-icon {
    font-size: 24px;
    color: white;
}