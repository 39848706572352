
.card {
    min-height: 200px;
}

.card a {
    color: var(--adito-primary-color);
    text-decoration: none;
}

.controls {
    display: flex;
    gap: 8px;
    align-items: center;
}

.controls {
    font-size: 18px;
}

.card section {
    background-color: var(--adito-primary-card-color);
    color: var(--adito-primary-font-color);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    position: absolute;
    inset: 0;
    padding: 0px;
    padding-top: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 200px;
}

.card .header {
    cursor: grab;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 8px;
    padding-block: 8px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 16px;
}

.card .header:hover, .card:hover .header {
    background-color: rgba(224, 224, 224, 0.73);
    background-color: var(--adito-primary-card-header-color-variant);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: var(--adito-primary-card-header-font-color-variant);
}

.card:hover .header a {
    color: var(--adito-secondary-font-color);
}

.card .header kol-icon {
    display: flex;
    font-size: 18px;
}

.card .header h4 {
    flex-grow: 1;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.card .content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: 10px;
    padding-right: 8px;
    overflow: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 16px;
    padding-top: 0px;
}