:root {
  /* GLOBAL */
  --adito-primary-color: #4499DC;
  --adito-primary-color-variant: #2e6998;

  --adito-secondary-color: #FFFFFF;
  --adito-secondary-color-variant: #F5F5F5;
  --adito-bg-filter: brightness(0.6);

  /* FONTS */
  --adito-primary-font: "Roboto","Helvetica","Arial",sans-serif;
  --adito-primary-font-color: #000000DD;

  --adito-secondary-font-color: #FFFFFF;
  --adito-secondary-font-color-variant: #727272;

  /* BUTTONS */
  --adito-primary-button-beckground: #4499DC;
  --adito-primary-button-color: #FFFFFF;

  /* LISTS */
  --adito-primary-list-background: #FFFFFF;
  --adito-primary-list-background-variant: #F5F5F5;
  --adito-primary-list-font-color: #000000de;

  /*CUSTOM ELEMENTS*/

  /*AVATAR*/
  --adito-avatar-primary-color: #ffc0cb;

  /* DROP DOWN */
  --adito-primary-dropdown-color: var(--adito-secondary-color, #FFFFFF);
  --adito-primary-dropdown-color-variant: var(--adito-secondary-color-variant, #FFFFFF);

  /* DROP DOWN LIST / SELECT */
  --adito-primary-list-box-color: #464646;
  --adito-primary-list-box-color-variant: #3d3c3c;
  --adito-primary-list-box-font-color: var(--adito-secondary-font-color);
  --adito-primary-list-box-active-color: #45535f;

  /* CARD */
  --adito-primary-card-header-color: var(--adito-secondary-color, #FFFFFF);
  --adito-primary-card-header-color-variant: var(--adito-secondary-color-variant, #FFFFFF);
  --adito-primary-card-color: #FFFFFF;
  --adito-primary-card-header-font-color-variant: var(--adito-primary-font-color);

  /* kolibrik variables */

  /* colors */
  --kolibri-color-primary: var(--adito-primary-color);
  --kolibri-color-primary-variant: var(--adito-primary-color-variant);
  --kolibri-font-family: var(--adito-primary-font);
}

html[data-theme-mode=adito] {
  --adito-primary-color: #14466C;
  --adito-primary-color-variant: #0f314b;
  --adito-primary-color-active: #414e56;
  --adito-bg-filter: grayscale(80%) brightness(0.5);

  --adito-secondary-color: #464646;
  --adito-secondary-color-variant: #2d2d2d;

  /* FONTS */
  /*--adito-primary-font: "Roboto","Helvetica","Arial", sans-serif;*/
  /*--adito-primary-font-color: #FFFFFF;*/
  /*--adito-secondary-font-color: #FFFFFF;*/

  /* BUTTONS */
  --adito-primary-button-beckground: red;
  --adito-primary-button-color: white;

  /* MENU LIST */
  --adito-primary-list-font-color: #FFFFFF;
  --adito-primary-list-background: var(--adito-secondary-color);
  --adito-primary-list-background-variant: var(--adito-secondary-color-variant);

  /* DROP DOWN LIST / SELECT */
  --adito-primary-list-box-color: #1f1f1f;
  --adito-primary-list-box-color-variant: #4b4b4b;
  --adito-primary-list-box-active-color: var(--adito-primary-color-active);

  /*AVATAR*/
  --adito-avatar-primary-color: var(--adito-primary-color);

  /*NAVIGATION*/
  --adito-primary-header-navigation-color: var(--adito-secondary-color);
  --adito-primary-header-navigation-active-color: var(--adito-secondary-color-variant);

  /*CARD*/
  --adito-primary-card-header-color-variant: var(--adito-primary-color, #FFFFFF);
  --adito-primary-card-header-font-color-variant: var(--adito-secondary-font-color);
}

/** kolibri helper variables
    --border-radius: var(--kolibri-border-radius, 5px);
    --font-family: var(--kolibri-font-family, BundesSans Web, Calibri, Verdana, Arial, Helvetica, sans-serif);
    --font-size: var(--kolibri-font-size, 16px);
    --spacing: var(--kolibri-spacing, 0.25rem);
    --border-width: var(--kolibri-border-width, 1px);
    --color-primary: var(--kolibri-color-primary, #004b76);
    --color-primary-variant: var(--kolibri-color-primary-variant, #0077b6);
    --color-danger: var(--kolibri-color-danger, #c0003c);
    --color-warning: var(--kolibri-color-warning, #c44931);
    --color-success: var(--kolibri-color-success, #005c45);
    --color-subtle: var(--kolibri-color-subtle, #576164);
    --color-light: var(--kolibri-color-light, #ffffff);
    --color-text: var(--kolibri-color-text, #202020);
    --color-mute: var(--kolibri-color-mute, #f2f3f4);
    --color-mute-variant: var(--kolibri-color-mute-variant, #bec5c9);

 */

@layer kol-theme-global {
  :host {
    --color-primary: orange !important;
    --color-primary-variant: red !important;
  }

  :root {
    --color-primary: orange !important;
    --color-primary-variant: red !important;
  }
}

body {
  font-family: var(--adito-primary-font);
}


html, body, #root {
  height: 100%;
}

.grid-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.flex-align-center {
  align-items: center;
  gap: 10px;
}

.flex-box {
  display: flex;
}




/* kolibrik button */
