
.content {
    position: relative;
    flex-direction: row-reverse;
    margin: 25px 15px;
    width: 100%;
    position: relative;
}

.content .innerContent {
    width: 100%;
    position: relative;
    height: 100%;
}

.content .sideContent {
    right: 0;
}

.content .addButton {
    display: flex;
}

