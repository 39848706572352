
.card {
    width: 100%;
    height: auto;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    padding: 15px;
}

.cardHeader {
    background: white;
    margin-bottom: 15px;
}