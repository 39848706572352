
.content {
    display: flex;
    gap: 16px;
    margin: 25px 15px;
    flex-grow: 1;
}

.content .customerInfo {
    width: 33.33%;
}

.content .customerDetails {
    width: 66.667%;
}

.content .customerDetailsCard {
    padding: 0;
}

:global(.customer-tabs .tab-content) {
    padding: 15px;
}

:global(.customer-tabs .tab-header ul li) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    min-width: 70px;
    text-align: center;
}