.main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.navBar {
    width: 200px;
    position: fixed;
    height: 100%;
    background: #333333;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
}

.topNavigation {
    width: calc(100% - 200px);
    min-height: 20px;
    position: absolute;
    left: 200px;
    top: 0;
    -webkit-box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 20px 0px rgba(45, 45, 45, 0.75);
    box-shadow: 0px 2px 20px 0px rgba(45, 45, 45, 0.75);
    z-index: 5;
}

.topBar {
    width: 100%;
    min-height: 20px;
    position: relative;
    background: white;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.topNavigation .titleBar {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #F5F5F5;
}

.topNavigation .titleBar h1 {
    margin: 0;
    padding: 0;
    margin-left: 20px;
    font-family: var(--adito-primary-font);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
}


.topBar .userMenu .user {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--adito-avatar-primary-color);
    color: var(--adito-secondary-font-color);
    font-size: 22px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 20px;
}


.topBar .contactMenu {
    margin: 0 auto;
}

.content {
    margin-top: 120px;
    margin-left: 200px;
    display: flex;
    flex-grow: 1;
}

.navMenu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navMenu li {
    width: 100%;
    padding: 10px 10px;
    color: white;
    margin: 0;
    display: flex;
    align-items: center;

}

.navMenu li:hover {
    background: #4b4b4b;
}

.navMenu li a {
    color: white;
    text-align: left;
    display: flex;
    justify-content: center;
    text-decoration: none;
    gap: 10px;
}

.headerNavigationIcon {
    font-size: 25px;
    color: var(--adito-secondary-font-color);
}

.headerNavigation {
    width: 100%;
    display: flex;
    background: #E31F4E;
    justify-content: center;
    gap: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.headerNavigation a {
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.headerNavigation a:hover {
    background-color: var(--adito-primary-header-navigation-color, #e8708c);
}

.headerNavigationLinkActive {
    background: var(--adito-primary-header-navigation-active-color, #7e112b);
}

.userMenu {
    float: right;
}

.userAvatarMenu {
    margin-right: 10px;
}

.contactMenuList ul {
    width: 500px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.contactMenuList ul li {
    padding: 0;
    margin: 0;
}

.contactMenuList ul li a, .contactMenuList ul li span {
    width: 100%;
    padding: 8px 16px;
    /*display: flex;*/
    align-items: center;
    color: #4b4b4b;
    /*gap: 15px;*/
    text-decoration: none;
}

.contactMenuList ul li span kol-icon * {
    position: absolute;
}

.contactMenuList ul li ul {
    margin-left: 25px;
}

.contactMenuList ul li ul li {
    float: left;
}

.contactMenuList ul li ul li a, .contactMenuList ul li ul li span {
    color: blue;
}