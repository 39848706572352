
.tabs {
    width: 100%;
}

.tabs .tabHeader {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #c2c2c2;
}

.tabs .tabHeader ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tabs .tabHeader li {
    padding: 6px;
    float: left;
    background-color: #f3f3f3;
    margin-right: 5px;
}

.tabs .tabHeader li:hover, .tabs .tabHeader li.active {
    background-color: #c2c2c2;
}

.tabs .tabHeader li:not(.active):hover {
    cursor: pointer;
}

.tabs .tabContent {
    width: 100%;
}

.tabs .tabContent div.active {
    display: block;
    width: 100%;
}

.tabs .tabContent div.inactive {
    display: none;
}