.aditoDropDownButton {
    position: relative;
}

.aditoDropDownContent {
    background-color: var(--adito-primary-dropdown-color, transparent);
    border-radius: 4px;
    max-width: 730px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    position: absolute;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    z-index: 1;
}

.aditoDropDownContent.hidden {
    visibility: hidden;
    opacity: 0;
}

.aditoDropDownContent.show {
    visibility: visible;
    opacity: 1;
}