
.listBox {
    position: relative;
}

.listBox span {
    text-align: center;
    border-bottom: 1px solid #ffffff;
    padding: 5px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
    text-wrap: nowrap;
    color: white;
}

.listBox span:hover {
    cursor: pointer;
}

.listBox ul {
    position: absolute;
    list-style-type: none;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 6px;
    margin: 0;
    background: var(--adito-primary-list-box-color);
    left: 0;
    opacity: 0;
}

.listBox ul.hidden {
    opacity: 0;
    visibility: hidden;
}

.listBox ul.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
}

.listBox ul li {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--adito-primary-list-box-font-color);
    text-wrap: nowrap;
}

.listBox ul li:hover {
    background-color: var(--adito-primary-list-box-color-variant);
    cursor: pointer;
}

.listBox ul li.active {
    background: var(--adito-primary-list-box-active-color);
}