
.tableAvatar {
    width: 50px;
    height: 50px;
}

.tableAvatar * {
    width: 50px;
    height: 50px;
}

.gridContent {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
}

.gridContent .innerContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
}

.sideContent {
    position: fixed;
    width: 400px;
    height: 100vh;
    top: 60px;
    background: white;
    overflow: hidden;
    transition: width 0.20s ease-in-out;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.sideContent.hidden {
    width: 0px;
}

.sideContent .innerContainer {
    padding: 15px;
    padding-top: 50px;
}