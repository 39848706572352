.multiMenuList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: all ease-in-out;
}

.multiMenuList ul li ul.hidden {
    height: 0px;
    visibility: hidden;
}

.multiMenuList ul li ul.collapsed {
    height: auto;
    visibility: visible;
}

.multiMenuList ul li {
    background-color: var(--adito-primary-list-background, #ffffff);
    text-wrap: nowrap;
}

.multiMenuList ul.main li {
    background-color: var(--adito-primary-list-background, #ffffff);
}

.multiMenuList ul.main li:hover {
    background-color: var(--adito-primary-list-background-variant, #F5F5F5);
}

.multiMenuList ul.sub li:first-child {
    box-shadow: 0px 7px 11px -7px rgba(0,0,0,0.5) inset;
    -webkit-box-shadow: 0px 7px 11px -7px rgba(0,0,0,0.5) inset;
    -moz-box-shadow: 0px 7px 11px -7px rgba(0,0,0,0.5) inset;
}

.multiMenuList ul.sub li:last-child {
    box-shadow: -1px -8px 10px -7px rgba(0,0,0,0.47) inset;
    -webkit-box-shadow: -1px -8px 10px -7px rgba(0,0,0,0.47) inset;
    -moz-box-shadow: -1px -8px 10px -7px rgba(0,0,0,0.47) inset;
}

.multiMenuList ul li ul {
    padding: 0;
    margin: 0;
}

.multiMenuList ul li a, .multiMenuList ul li span {
    text-decoration: none;
    color: var(--adito-primary-list-font-color, #000000de);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    padding: 6px 16px;
}

.multiMenuList ul li span:hover {
    cursor: pointer;
}

.multiMenuList ul li a kol-icon,.multiMenuList ul li span kol-icon {
    font-size: 20px;
}